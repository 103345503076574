/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, ReactNode, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { api } from '../api';
import { useReactQuerySubscription } from '../api/sockets';
import { useProtectedContext } from '../components';
import { PaymentDialog } from '../components/payment-dialog';
import { useAuth } from '../contexts/auth-context';
import { CheckoutSession, PaymentIntent } from '../generated';
import { trackEvent } from '../utils/track-events';

export const DashboardContext = createContext<{
  paymentCode: string;
  setPaymentCode: (paymentCode: string) => void;
  paymentInitiated: boolean;
  setPaymentInitiated: (paymentInitiated: boolean) => void;
  checkoutSession: CheckoutSessionWithIsTest | undefined;
  setCheckoutSession: (checkoutSession: CheckoutSessionWithIsTest | undefined) => void;
  paymentIntent: PaymentIntentWithIsTest | undefined;
  setPaymentIntent: (paymentIntent: PaymentIntentWithIsTest | undefined) => void;
  message: string;
  setMessage: (message: string) => void;
  handlePaymentCode: (paymentCode: string, numberOfTickets?: number) => Promise<void>;
  setPaymentModalOpen?: (open: boolean) => void;
  paymentModalOpen: boolean;
  paymentCodeError?: boolean;
  setPaymentCodeError?: (error: boolean) => void;
}>({
  paymentCode: '',
  // @ts-ignore
  setPaymentCode: (paymentCode: string) => {},
  paymentInitiated: false,
  // @ts-ignore
  setPaymentInitiated: (paymentInitiated: boolean) => {},
  checkoutSession: undefined as CheckoutSessionWithIsTest | undefined,
  // @ts-ignore
  setCheckoutSession: (checkoutSession: CheckoutSessionWithIsTest | undefined) => {},
  paymentIntent: undefined as PaymentIntentWithIsTest | undefined,
  // @ts-ignore
  setPaymentIntent: (paymentIntent: PaymentIntentWithIsTest | undefined) => {},
  message: '',
  // @ts-ignore
  setMessage: (message: string) => {},
  // @ts-ignore
  handlePaymentCode: (paymentCode: string) => {},
  paymentModalOpen: false,

  // @ts-ignore
  setPaymentModalOpen: (open: boolean) => {},
  paymentCodeError: false,
});

export type CheckoutSessionWithIsTest = { checkoutSession: CheckoutSession; isTest: boolean };
export type PaymentIntentWithIsTest = { paymentIntent: PaymentIntent; isTest: boolean };

export const DashboardContextProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [paymentCode, setPaymentCode] = useState('');
  const [paymentInitiated, setPaymentInitiated] = useState(false);
  const [checkoutSession, setCheckoutSession] = useState<CheckoutSessionWithIsTest>();
  const [paymentIntent, setPaymentIntent] = useState<PaymentIntentWithIsTest>();
  const [message, setMessage] = useState('');
  const [paymentCodeError, setPaymentCodeError] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const { closeShareTicket } = useProtectedContext();

  const handlePaymentCode = async (paymentCode: string, numberOfTickets = 1) => {
    setPaymentInitiated(true);
    setMessage('');

    try {
      const { data: facility } = await api.facilitiesControllerGetFacilityByName({
        facilityName: paymentCode as string,
      });

      const { data: checkoutSession } = await api.paymentsControllerInitPaymentForFacility({
        facilityId: facility.id,
        initPaymentDto: {
          numberOfTickets,
        },
      });

      const { data: paymentIntent } = await api.paymentsControllerInitPaymentIntentForFacility({
        facilityId: facility.id,
        initPaymentDto: {
          numberOfTickets,
        },
      });

      setPaymentModalOpen(true);

      trackEvent('payment-initiated');

      localStorage.setItem('prefillPaymentCode', paymentCode);

      setCheckoutSession({
        checkoutSession,
        isTest: facility.isTestFacility,
      });
      setPaymentIntent({
        paymentIntent,
        isTest: facility.isTestFacility,
      });
    } catch (error) {
      console.error(error);
      setMessage(t('Felaktig kod'));
      setPaymentInitiated(false);
      setPaymentCodeError(true);
    }
  };

  const { closePersonalTicket } = useProtectedContext();

  const handlePaymentEvent = (data: string) => {
    setPaymentCode(data);
    setPaymentModalOpen(true);
    handlePaymentCode(data);
    closePersonalTicket();
  };

  useReactQuerySubscription(user?.user?.id, handlePaymentEvent, closeShareTicket);

  return (
    <DashboardContext.Provider
      value={{
        paymentCode,
        setPaymentCode,
        paymentInitiated,
        setPaymentInitiated,
        checkoutSession,
        setCheckoutSession,
        paymentIntent,
        setPaymentIntent,
        message,
        setMessage,
        handlePaymentCode,
        setPaymentModalOpen,
        paymentModalOpen,
        paymentCodeError,
        setPaymentCodeError,
      }}
    >
      {children}
      <PaymentDialog open={paymentModalOpen} onClose={() => setPaymentModalOpen(false)} />
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardContextProvider');
  }

  return context;
};
