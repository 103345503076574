import { ReactNode } from 'react';

import { Dialog as HeadlessDialog, DialogPanel } from '@headlessui/react';
export const Dialog = ({
  children,
  open,
}: {
  children: ReactNode;

  open: boolean;
}) => {
  return (
    <HeadlessDialog open={open} onClose={() => console.log('noop')} className="relative z-10">
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-gray-900/95">
        <div className="flex min-h-full justify-center text-center items-center w-full">
          <DialogPanel
            transition
            className="relative transform rounded-lg text-left w-screen p-2 bg-white"
          >
            {children}
          </DialogPanel>
        </div>
      </div>
    </HeadlessDialog>
  );
};
