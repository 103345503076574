import { ChangeEvent, useState } from 'react';
import { useDashboardContext } from '../context/dashboard';
import { handleinteractions } from '../helpers';
import { TICKET_PURCHASE_LIMIT } from '../utils/constants';
import { useDebounce } from './use-debounce';
import { MinusIcon, PlusIcon, TicketIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { api } from '../api';

export const useNumberOfTickets = (onClose: () => void) => {
  const [numberOfTicketsLoading, setNumberOfTicketsLoading] = useState(false);
  const [numberOfTickets, setNumberOfTickets] = useState(1);
  const [tempNumberOfTickets, setTempNumberOfTickets] = useState(1);

  const ticketMissmatch = tempNumberOfTickets !== numberOfTickets;

  const { paymentCode, handlePaymentCode, paymentIntent } = useDashboardContext();

  const { data: facility } = useQuery({
    queryKey: ['facility', paymentCode],
    queryFn: async () => {
      const { data } = await api.facilitiesControllerGetFacilityByName({
        facilityName: paymentCode as string,
      });

      return data;
    },
  });

  const handleTempNumberOfTickets = (i: number) => {
    let val = i;

    if (val > TICKET_PURCHASE_LIMIT) {
      val = TICKET_PURCHASE_LIMIT;
    }
    setTempNumberOfTickets((prev) => prev + val);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    changeNumberOfTickets(Number(event.target.value));
  };

  const handleButtonPress = (i: number) => {
    changeNumberOfTickets(i);
  };

  const debouncedHandleInputChange = useDebounce(handleInputChange, 1000);
  const debouncedHandleButtonPress = useDebounce(handleButtonPress, 1000);

  const resetNumberOftickets = () => {
    setNumberOfTickets(1);
    setTempNumberOfTickets(1);
  };

  const changeNumberOfTickets = (i: number) => {
    let numberOftickets = i;
    if (numberOftickets <= 0) {
      setNumberOfTickets(1);
      setTempNumberOfTickets(1);
      return;
    }
    if (i > TICKET_PURCHASE_LIMIT) {
      numberOftickets = TICKET_PURCHASE_LIMIT;
    }
    setNumberOfTickets(numberOftickets);
    setTempNumberOfTickets(numberOftickets);
    setNumberOfTicketsLoading(true);
    handlePaymentCode(paymentCode, numberOftickets).finally(() => setNumberOfTicketsLoading(false));
  };

  const SelectTicketsComponent = () => (
    <div>
      <div className="p-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <TicketIcon className="h-6 w-6 text-center  primary-main" />
            <h5 className="text-xl font-medium text-black">Select Tickets</h5>
          </div>
          <div>
            <button
              className="p-2 flex items-center justify-center rounded-full bg-slate-100 hover:bg-slate-200"
              onClick={onClose}
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
      <div className="bg-slate-100 rounded-lg p-5 shadow-sm mx-2">
        <div className="flex justify-between items-center">
          <div className="font-medium text-lg">
            {(paymentIntent?.paymentIntent.metadata as Record<string, string>)['facilityName']}
          </div>
          <div className="text-lg font-semibold">
            {facility?.paymentAmount} {paymentIntent?.paymentIntent.currency.toUpperCase()}
          </div>
        </div>

        <div className="flex items-center justify-between mt-4">
          <div className="text-sm text-gray-500">Quantity</div>
          <div className="flex items-center space-x-3">
            <button
              className="h-10 w-10 rounded-full border flex justify-center items-center bg-white"
              {...handleinteractions(() => {
                debouncedHandleButtonPress(tempNumberOfTickets - 1);
                handleTempNumberOfTickets(-1);
              })}
            >
              <MinusIcon className="h-6 w-6" />
            </button>
            <span className="w-8 text-center text-lg">{tempNumberOfTickets}</span>
            <button
              className="h-10 w-10 rounded-full border flex justify-center items-center bg-white"
              {...handleinteractions(() => {
                debouncedHandleButtonPress(tempNumberOfTickets + 1);
                handleTempNumberOfTickets(+1);
              })}
            >
              <PlusIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
      <div className="flex p-4">
        <h2 className="flex-1 text-xl">Total</h2>
        <h2 className="flex-1 text-xl text-end">
          {(paymentIntent?.paymentIntent.amount ?? 0) / 100}{' '}
          {paymentIntent?.paymentIntent.currency.toUpperCase()}
        </h2>
      </div>
    </div>
  );

  return {
    numberOfTickets,
    numberOfTicketsLoading,
    tempNumberOfTickets,
    ticketMissmatch,
    changeNumberOfTickets,
    debouncedHandleButtonPress,
    debouncedHandleInputChange,
    handleTempNumberOfTickets,
    resetNumberOftickets,
    SelectTicketsComponent,
    setNumberOfTickets,
    setTempNumberOfTickets,
  };
};
