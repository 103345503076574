import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/auth-context';
import { createContext, useContext, useEffect, useState } from 'react';
import { Drawer } from './drawer';
import { DashboardContextProvider } from '../context/dashboard';
import { AddPhoneDialog } from './add-phone-dialog';
import { AddEmailDialog } from './add-email-dialog';
import { CheckedInQrCode, PaidQRCode, QRCode } from '../generated';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../api';
import { toast } from 'react-toastify';

const ProtectedContext = createContext({
  drawerOpen: false,
  closeDrawer: () => {},
  openDrawer: () => {},
  toggleDrawer: () => {},
  addPhoneOpen: false,
  closeAddPhone: () => {},
  openAddPhone: () => {},
  addEmailOpen: false,
  closeAddEmail: () => {},
  openAddEmail: () => {},

  personalTicketOpen: false,
  closePersonalTicket: () => {},
  openPersonalTicket: () => {},

  shareTicketOpen: false,
  closeShareTicket: () => {},
  openShareTicket: () => {},

  currentTicket: undefined as PaidQRCode | CheckedInQrCode | QRCode | undefined,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setCurrentTicket: (ticket: PaidQRCode | CheckedInQrCode | QRCode) => {},
});

export const ProtectedRoute = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [addPhoneOpen, setAddPhoneOpen] = useState(false);
  const [addEmailOpen, setAddEmailOpen] = useState(false);
  const [personalTicketOpen, setPersonalTicketOpen] = useState(false);
  const [shareTicketOpen, setShareTicketOpen] = useState(false);
  const [currentTicket, setCurrentTicket] = useState<PaidQRCode | CheckedInQrCode | QRCode>();
  const closeDrawer = () => setDrawerOpen(false);
  const openDrawer = () => setDrawerOpen(true);
  const toggleDrawer = () => setDrawerOpen((prev) => !prev);

  const closeAddPhone = () => setAddPhoneOpen(false);
  const openAddPhone = () => setAddPhoneOpen(true);

  const closeAddEmail = () => setAddEmailOpen(false);
  const openAddEmail = () => setAddEmailOpen(true);

  const closePersonalTicket = () => setPersonalTicketOpen(false);
  const openPersonalTicket = () => setPersonalTicketOpen(true);

  const closeShareTicket = () => setShareTicketOpen(false);
  const openShareTicket = () => setShareTicketOpen(true);

  const { isAuthenticated, loading } = useAuth();

  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const { mutate: claim } = useMutation({
    mutationFn: async (shareToken: string) => {
      const { data } = await api.slotsControllerClaimSlot({
        shareToken,
      });

      return data;
    },
    onSuccess: () => {
      localStorage.removeItem('shareToken');
      queryClient.invalidateQueries(['currentUser']);
      queryClient.invalidateQueries(['qr-code']);
      toast.success('Ticket claimed successfully');
    },
  });

  useEffect(() => {
    const shareToken = localStorage.getItem('shareToken');

    if (shareToken) {
      claim(shareToken);
    }
  }, []);

  if (loading) return null;

  if (!isAuthenticated) {
    if (pathname.includes('/facility/')) {
      localStorage.setItem('redirect', pathname);
    }

    return <Navigate to={`/login${window.location.search}`} />;
  }

  return (
    <ProtectedContext.Provider
      value={{
        drawerOpen,
        closeDrawer,
        openDrawer,
        toggleDrawer,
        addPhoneOpen,
        closeAddPhone,
        openAddPhone,
        addEmailOpen,
        closeAddEmail,
        openAddEmail,
        personalTicketOpen,
        closePersonalTicket,
        openPersonalTicket,
        shareTicketOpen,
        closeShareTicket,
        openShareTicket,
        currentTicket,
        setCurrentTicket,
      }}
    >
      <DashboardContextProvider>
        <Outlet />
        <Drawer open={drawerOpen} onClose={closeDrawer} />
        <AddPhoneDialog open={addPhoneOpen} onClose={closeAddPhone} />
        <AddEmailDialog open={addEmailOpen} onClose={closeAddEmail} />
      </DashboardContextProvider>
    </ProtectedContext.Provider>
  );
};

export const useProtectedContext = () => {
  const context = useContext(ProtectedContext);

  if (!context) {
    throw new Error('useProtectedContext must be used within a ProtectedContextProvider');
  }

  return context;
};
