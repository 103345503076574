import { useTranslation } from 'react-i18next';

export const AddPhoneButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <>
      <span className="text-center text-blue-400 text-sm">{t('general.account-safety')}</span>
      <div className="relative w-full">
        <button
          type="button"
          className="w-full rounded-full  animate-pulse bg-white border-4 border-blue-400   px-3.5 py-2 text-sm font-medium text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
          onClick={onClick}
        >
          {t('general.add-phone-number')}
        </button>
        <span className="absolute inset-0 bg-blue-500 rounded-full z-[-1]" />
      </div>
    </>
  );
};
