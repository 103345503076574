/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../api';
import { useAuth } from '../hooks/use-auth';
import { Spinner } from './spinner';
import { Dialog } from './ui/dialog';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { useQueryClient } from '@tanstack/react-query';

export const AddEmailDialog = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  const { t } = useTranslation();

  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const queryClient = useQueryClient();

  const handleEmailSubmit = async (email: string) => {
    setLoading(true);
    setEmail(email);
    try {
      await api.usersControllerUpdate({
        id: user?.id as string,
        updateUserDto: {
          email,
        },
      });
      setEmail('');
      setLoading(false);
      queryClient.invalidateQueries({ queryKey: ['auth'] });
      queryClient.invalidateQueries({ queryKey: ['currentUser'] });
      onClose();
    } catch (error: any) {
      console.error(error);
      setMessage(error?.response?.data?.message || 'An error occurred');
      setLoading(false);
    }
  };

  const render = () => {
    if (loading)
      return (
        <div className="flex justify-center">
          <Spinner />
        </div>
      );

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleEmailSubmit(email);
        }}
      >
        <h2 className="text-blue-300 text-lg mb-2">{t('general.account-safety-email')}</h2>
        {message && <p className="text-red-500">{message}</p>}
        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button type="submit" color="blue" className="w-full self-end mt-8">
          {t('general.add-email')}
        </Button>
      </form>
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {render()}
    </Dialog>
  );
};
