import { ShareIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';
import { useProtectedContext } from './protected-route';
import { useMutation } from '@tanstack/react-query';
import { api } from '../api';
import { Spinner } from './spinner';
import { useEffect } from 'react';
import { CheckedInQrCode } from '../generated';

export const ShareTicket = () => {
  const { t } = useTranslation();
  const { closeShareTicket, shareTicketOpen, currentTicket } = useProtectedContext();

  const { mutate, data, isError, isLoading, error } = useMutation({
    mutationFn: async (slotId: string) => {
      const { data } = await api.slotsControllerGenerateSlotShareToken({
        id: slotId,
      });

      return data;
    },
  });

  useEffect(() => {
    if (shareTicketOpen && !isLoading && currentTicket?.state === 'CHECKED_IN') {
      mutate(currentTicket?.slotId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shareTicketOpen]);

  const shareLink = `${window.location.origin}?shareToken=${encodeURI(data?.shareToken || '')}`;

  const handleShare = () => {
    if (navigator.share && data) {
      navigator
        .share({
          title: `Claim my ticket at ${(currentTicket as CheckedInQrCode).facilityName}`,
          text: `I shared my ticket with you at ${
            (currentTicket as CheckedInQrCode).facilityName
          }. Claim it here: ${shareLink}`,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      console.log('web share not supported');
    }
  };

  if (isError) {
    return (
      <div className="flex justify-center items-center h-full">
        <span className="text-red-400">
          {/* @ts-expect-error no type for error */}
          {error?.response?.data?.message}
        </span>
      </div>
    );
  }

  return (
    <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0 flex-col gap-8">
      <span className="text-xl text-white">{t('tickets.scan-with-camera')}</span>
      <div className="p-6 bg-white rounded-3xl relative overflow-hidden">
        <QRCode
          value={shareLink}
          size={255}
          style={{
            opacity: isLoading ? 0 : 1,
            transition: !isLoading ? 'opacity 0.3s' : 'none',
          }}
        />
        {isLoading && (
          <div className="absolute inset-0 flex justify-center items-center bg-gray-500">
            <Spinner />
          </div>
        )}
      </div>
      {/* @ts-expect-error expects call */}
      {navigator.share && (
        <>
          <div className="flex gap-4 w-full items-center">
            <div className="flex-1 border" />
            <span className="text-xl text-white">{t('tickets.or')}</span>
            <div className="flex-1 border" />
          </div>

          <button
            onClick={handleShare}
            disabled={isLoading}
            type="button"
            className="border-blue-400 border-2 rounded-full w-full bg-white p-2 text-lg font-medium text-black shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white flex gap-4 justify-center items-center"
          >
            <>
              {t('tickets.share')}
              <ShareIcon className="h-6 w-6" />
            </>
          </button>
        </>
      )}
      <button onClick={closeShareTicket} className="bg-textPrimary rounded-full p-2">
        <XMarkIcon className="size-12 text-bgPrimary" />
      </button>
    </div>
  );
};
