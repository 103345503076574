import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { socket } from './socket';
import { toast } from 'react-toastify';

export const useReactQuerySubscription = (
  userId?: string,
  handlePaymentEvent?: (data: string) => void,
  closeShareTicket?: () => void,
) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!userId || socket.connected) return;
    socket.connect();

    function onConnect() {
      console.log('recovered?', socket.recovered);
      console.log('Connected');
      socket.emit('join-room', userId);
    }

    socket.on('connect', onConnect);

    socket.on('qr-code', (data) => {
      console.log('Recieved event hoy', JSON.stringify(data));
      queryClient.invalidateQueries({
        queryKey: [`qr-code`],
      });
    });

    socket.on('close-share-ticket', () => {
      console.log('Close share ticket');
      closeShareTicket && closeShareTicket();
      toast.success('Ticket claimed successfully');
    });

    socket.on('payment', (data) => {
      console.log('Recieved payment event', JSON.stringify(data));
      handlePaymentEvent && handlePaymentEvent(data);
    });

    socket.on('scanning', () => {
      console.log('Scanning!!!');
      queryClient.invalidateQueries({
        queryKey: [`qr-code`],
      });
    });

    socket.on('scanning-aborted', () => {
      console.log('Scanning aborted');
      queryClient.invalidateQueries({
        queryKey: [`qr-code`],
      });
    });

    return () => {
      socket.off('connect', onConnect);
      socket.removeAllListeners();
      socket.emit('leave-room', userId);
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryClient, userId]);
};
