/* eslint-disable react/no-unknown-property */
import { CreditCardIcon } from '@heroicons/react/24/outline';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptionsClientSecret } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../api';
import { useDashboardContext } from '../context/dashboard';
import { FacilityForPayments } from '../generated';
import { classNames, handleinteractions } from '../helpers';
import { useNumberOfTickets } from '../hooks/use-number-of-tickets';
import { useSwish } from '../hooks/use-swish';
import { CheckoutFormElements } from './checkout-form-elements';
import { CheckoutFormExpress } from './checkout-form-express';
import { Dialog } from './dialog';
import { InitPaymentForm } from './payments/init-payment-form';
import { Spinner } from './spinner';

interface Props {
  open: boolean;
  onClose: () => void;
}

const stripePromise = loadStripe(
  import.meta.env.VITE_STRIPE_PK ||
    'pk_test_51OgZqKE26LEtC5Eq9TNj0Dm62zLIYBjuXqaW8Xmtgq7HTq1dAA6rfDwdJibZeqBv61uq9hae7SbOrDeCsSLsEZcG00GrhpWg5H',
);

const stripeTestPromise = loadStripe(
  import.meta.env.VITE_STRIPE_TEST_PK ||
    'pk_test_51OgZqKE26LEtC5Eq9TNj0Dm62zLIYBjuXqaW8Xmtgq7HTq1dAA6rfDwdJibZeqBv61uq9hae7SbOrDeCsSLsEZcG00GrhpWg5H',
);

export const PaymentDialog = ({ open, onClose }: Props) => {
  const [showCard, setShowCard] = useState(false);

  const [_loading, setLoading] = useState(false);

  const [selectedFacility, setSelectedFacility] = useState<FacilityForPayments>();

  const {
    setPaymentCode,
    paymentInitiated,
    setPaymentInitiated,
    checkoutSession,
    setCheckoutSession,
    setMessage,
    handlePaymentCode,
    setPaymentCodeError,
    paymentIntent,
  } = useDashboardContext();

  const navigate = useNavigate();

  useEffect(() => {
    const paymentCodeLocalStorage = localStorage.getItem('paymentCode');
    if (paymentCodeLocalStorage) {
      setPaymentCode(paymentCodeLocalStorage);
      handlePaymentCode(paymentCodeLocalStorage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearPaymentCode = () => {
    const paymentCodeLocalStorage = localStorage.getItem('paymentCode');
    if (paymentCodeLocalStorage) {
      localStorage.removeItem('paymentCode');
    }
    setPaymentCode('');
  };

  const handleClose = () => {
    clearPaymentCode();
    onClose();
    setMessage('');
    setLoading(false);
    setPaymentInitiated(false);
    setCheckoutSession(undefined);
    resetNumberOftickets();
    setShowCard(false);
    setSelectedFacility(undefined);
    if (setPaymentCodeError) setPaymentCodeError(false);
    navigate('/dashboard/paid');
  };

  const {
    numberOfTickets,
    numberOfTicketsLoading,
    ticketMissmatch,
    resetNumberOftickets,
    SelectTicketsComponent,
  } = useNumberOfTickets(handleClose);

  const { initSwish, swishLoading } = useSwish(numberOfTickets);

  const queryClient = useQueryClient();

  const handleConfirm = async () => {
    setLoading(true);

    try {
      const { data } = await api.paymentsControllerConfirmPaymentIntent({
        paymentIntentId: paymentIntent?.paymentIntent?.id as string,
      });

      if (['succeeded', 'paid'].includes(data.status as unknown as string)) {
        await queryClient.invalidateQueries(['qr-code']);
        handleClose();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const options: StripeElementsOptionsClientSecret = {
    clientSecret: paymentIntent?.paymentIntent?.client_secret || '',
    appearance: {
      theme: 'stripe',
    },
  };

  const loading = _loading || swishLoading || numberOfTicketsLoading;

  const render = () => {
    if (!paymentIntent && paymentInitiated) {
      return (
        <div className="m-auto p-4">
          <Spinner />
        </div>
      );
    }

    if (!paymentIntent || !checkoutSession) {
      return (
        <InitPaymentForm
          selectedFacility={selectedFacility}
          setSelectedFacility={setSelectedFacility}
        />
      );
    }

    return (
      <>
        <SelectTicketsComponent />
        <div className="relative">
          {(ticketMissmatch || loading) && (
            <div className="absolute inset-0 z-1 grid place-content-center">
              <Spinner className="border-gray-900" />
            </div>
          )}
          <div className={classNames('flex flex-col gap-5', ticketMissmatch ? 'opacity-50' : '')}>
            <div className="p-4">
              <button
                className={classNames(
                  'w-full p-2 bg-black text-white text-sm font-medium rounded-md mb-2',
                  loading || ticketMissmatch ? 'opacity-50' : '',
                )}
                disabled={loading || ticketMissmatch}
                {...handleinteractions(initSwish)}
              >
                <img className="w-6 inline-block mr-2" src="/swish.png" alt="Swish" />
                Betala med Swish
              </button>

              {paymentIntent &&
                !(loading || ticketMissmatch) &&
                (paymentIntent.isTest ? (
                  <>
                    <Elements options={options} stripe={stripeTestPromise}>
                      <CheckoutFormExpress
                        onConfirm={handleConfirm}
                        paymentIntent={paymentIntent.paymentIntent}
                      />
                      <CheckoutFormElements
                        onConfirm={handleConfirm}
                        paymentIntent={paymentIntent.paymentIntent}
                        showCard={showCard}
                        setShowCard={setShowCard}
                      />
                    </Elements>
                  </>
                ) : (
                  <>
                    <Elements options={options} stripe={stripePromise}>
                      <CheckoutFormExpress
                        onConfirm={handleConfirm}
                        paymentIntent={paymentIntent.paymentIntent}
                      />
                      <CheckoutFormElements
                        onConfirm={handleConfirm}
                        paymentIntent={paymentIntent.paymentIntent}
                        showCard={showCard}
                        setShowCard={setShowCard}
                      />
                    </Elements>
                  </>
                ))}
              <button
                className="w-full mt-2 px-4 bg-white border-2 border-black text-black text-lg rounded-md flex justify-center gap-2 items-center"
                disabled={loading || ticketMissmatch}
                {...handleinteractions(() => {
                  setLoading(true);
                  window.location.href = checkoutSession.checkoutSession.url;
                })}
              >
                <CreditCardIcon className="h-6 mr-2" aria-hidden="true" />
                <img src="/apple-pay-mark.svg" className="h-5" alt="Apple Pay" />
                <img src="/google-pay-mark.svg" className="h-10" alt="Google Pay" />
                <img src="/klarna-badge.png" className="h-9 -ml-3" alt="Klarna" />
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Dialog open={open}>
      <div className="rounded-lg flex flex-col gap-5 min-w-[224px]">{render()}</div>
    </Dialog>
  );
};
